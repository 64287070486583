<template>
  <v-container>
    <v-row justify="space-between" class="mt-5">
      <v-col cols="12" md="4" v-for="(item, i) in reportTitle" :key="i">
        <v-card elevation="1" @click="goTo(item.route)">
          <v-card-title class="body-1 orange darken-3 text-capitalize title">
            <div>
              <v-icon small left dark>
                {{ item.icon }}
              </v-icon>
            </div>
            <span class="white--text">{{ item.title }}</span>
          </v-card-title>
          <v-card-text class="pt-5">
            {{ item.description }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    reportTitle() {
      return [
        {
          icon: "mdi-file-document-edit",
          title: this.$t("monthly sales reports"),
          description: this.$t(
            "get monthly sales reports for every registered business"
          ),
          route: "MonthlySalesReport",
        },
        {
          icon: "mdi-file-document-edit",
          title: this.$t("quarterly sales reports"),
          description: this.$t(
            "get quarterly sales reports for every registered business"
          ),
          route: "QuarterlySalesReport",
        },
        {
          icon: "mdi-file-document-edit",
          title: this.$t("annual sales reports"),
          description: this.$t(
            "get annual sales reports for every registered business"
          ),
          route: "AnnualSalesReport",
        },
      ];
    },
  },

  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.title {
  height: 70px;
}
</style>
